// Eigenes CSS
import '../css/style.scss';

// Einfach mal hallo sagen
//console.log('Hallo Welt');

import 'jquery/dist/jquery';
const $ = require('jquery');
import 'superfish/src/js/superfish';
//import 'mmenu-js/src/mmenu';
//import 'slideout/dist/slideout';
import 'zeynepjs'
import 'jquery-match-height/dist/jquery.matchHeight-min';
//import 'swiper/swiper-bundle';
import Swiper, { Navigation, Pagination, Autoplay, Lazy } from 'swiper';
//SwiperCore.use([Autoplay]);
Swiper.use([Autoplay, Pagination, Navigation]);
import 'jquery-ui/ui/position';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/collapse';
//import PhotoSwipeLightbox from 'photoswipe/lightbox';
//import Masonry from 'masonry-layout';
//import 'masonry-layout/dist/masonry.pkgd';
import 'imagesloaded/imagesloaded';
import 'jquery-smooth-scroll/jquery.smooth-scroll';
//import Plyr from 'plyr';
//const player = new Plyr('#player');


//import 'resize-observer/dist/resize-observer';

//import Chart from 'chart.js/auto';

// import Chart from 'chart.js/auto';



//var fd_page = document.getElementsByTagName("html")[0];
//fd_page.style.opacity = "0";

/****************  INIT  *****************************/

var App = function ( ) {

    function initPageload() {
        //console.log("Pageload");
        //jQuery('html').css("opacity", "0.8");
        jQuery('html').fadeTo(300, 1);
    }

    function initSuperfish() { 
        //console.log("Superfish");
       // jQuery('ul.wpb-sf-menu').css('background-color','#ffff00');
       // jQuery('.fd-header-main-navigation ul').css('background-color','#ddff00');
       // jQuery('#wpb--menu').superfish({});
        /*
        jQuery('.wpb-fdtest').superfish({
            delay:       1000,                            // one second delay on mouseout
            animation:   {opacity:'show',height:'show'},  // fade-in and slide-down animation
            speed:       'fast',                          // faster animation speed
            cssArrows: false
        });
        */
    }

    function initMmenu() {
        new Mmenu( "#my-menu" );
        //, {
            // options
            /*
            "extensions": [ 
                "theme-dark",
                "pagedim-black"
            ],
            */
           /*
            navbar: {
                title: "title" // "10.000 Schritte Düsseldorf"
            }
        }, {
            // configuration
            offCanvas: {
                page: {
                    selector: "#ukd10k-page"
                }
            }
        });
        */
    }

    function initShrinkedHeader() {
        let shrink_active = false;
        jQuery(window).scroll(function () {
            var windowwidth = jQuery(window).width();
            //console.log("windowwidth: " + windowwidth);
         //   if (windowwidth > 750) {
             /*
                if (jQuery(window).scrollTop() > 75) {
                    jQuery('#fd-header').addClass('header-preshrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-preshrinked');
                }
                */
                if (jQuery(window).scrollTop() > 125) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    //console.log("shrinked");
                } else if (jQuery(window).scrollTop() < 125) {
                    jQuery('#fd-header').removeClass('header-shrinked');
                }

                if (jQuery(window).scrollTop() > 125) {
                    jQuery('#fd-page').addClass('main-shrinked');
                } else if (jQuery(window).scrollTop() < 125) {
                    jQuery('#fd-page').removeClass('main-shrinked');
                }



                /*
                if (jQuery(window).scrollTop() > 35) {
                    shrink_active = true;
                    
                } else if (jQuery(window).scrollTop() < 5) {
                    shrink_active = false;
                    
                }
                if ( shrink_active ) {
                    jQuery('#fd-header').addClass('header-shrinked');
                    jQuery('#fd-page').addClass('main-shrinked');
                } else {
                    jQuery('#fd-header').removeClass('header-shrinked');
                    jQuery('#fd-page').removeClass('main-shrinked');
                }
                */
          //  }
        });

    }

    function initRefreshAfterResize() {
        var dwidth = jQuery(window).width();
        jQuery(window).bind('resize', function(e){
            var wwidth = jQuery(window).width();
            if(dwidth!==wwidth){
            dwidth = jQuery(window).width();
            if (window.RT) clearTimeout(window.RT);
            window.RT = setTimeout(function(){
                this.location.reload(false); /* false to get page from cache */
            }, 1000);
        }
        });
    }

    function initMatchHeight() {
        //console.log("match Height");
        //jQuery(' .news-teaser-3col > div ').matchHeight({byRow: true});
        //jQuery(' .post ').matchHeight({byRow: true});
        //jQuery(' #home-introteaser-col .txt ').matchHeight({byRow: true});
    }


    function initTooltips() {
        jQuery(function () {
            //jQuery('[data-toggle="tooltip-right"]').tooltip({placement: 'right', html: true});
            jQuery('[data-toggle="tooltip-top"]').tooltip({placement: 'top', html: true, trigger: 'hover'});
            //jQuery('[data-toggle="tooltip-left"]').tooltip("show");
        })
    }

    function forceWindowResize() {
       // console.log("forceWindowResize");
       // newsSwiper.init();
        if (window.dispatchEvent) { // For modern browsers
          window.dispatchEvent(new Event('resize'));
        } else { // For older IE versions
          var event = document.createEvent('Event');
          event.initEvent('resize', true, true);
          window.dispatchEvent(event);
        }
      }


    function initSwiperHero() {
        //console.log("initSwiperHero");
        let slider_length_fd = $(".fdSwiper .swiper-wrapper .swiper-slide").length;
        //console.log("slider_length_fd: " + slider_length_fd);

        if ( slider_length_fd > 1 ) {
            const newsSwiper = new Swiper('.fdSwiper', {
                
                modules: [Navigation, Pagination, Autoplay, Lazy],
                slidesPerView: 1,
                spaceBetween: 0,

                loop: true,
                init: true,
                grabCursor: true,
                autoHeight: false,
                autoResize: true,
                resizeReInit: true,
                watchOverflow: false,
                
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                
                preloadImages: false,
                lazy: {
                    checkInView: false,
                    loadPrevNext: true,
                },
                pagination: { 
                    el: ".swiper-pagination",
                    clickable: 'true',
                },
                
                
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                
                
                
                on: {
                    init: function () {
                    //console.log('swiper initialized');
                    //initMatchHeight();
                    setTimeout(forceWindowResize, 1000);
                    
                    
                    //$(window).trigger("resize");
                    },
                },
                
            })  // end swiper
        }
        var slider_length = $(".fdSwiper .swiper-wrapper .swiper-slide").length;
        //console.log("slider_length: " + slider_length);
        if( slider_length < 2 ) {
            $('.swiper-wrapper-hero').addClass( "disabled" );
            $('.swiper-pagination-hero').addClass( "disabled" );
            $('.swiper-button-prev-hero').addClass( "disabled" );
            $('.swiper-button-next-hero').addClass( "disabled" );
        }
    }


    function initCookiesreopen() {
        //console.log("initCookiesreopen");
        jQuery('#fd_borlabs-cookie-preference a').on( "click", function() {
            //console.log( 'click' );
            window.BorlabsCookie.openCookiePreference();
          });
          
          //.on("click", window.BorlabsCookie.openCookiePreference);
    }


    function initCF7Styling() {
        var spans = document.getElementsByClassName("wpcf7-form-control-wrap");
        for(var i=0;i<spans.length;i++)
        {
            var input = spans[i].nextSibling;
            if(input != null)
            {
                if(input.tagName == "LABEL")
                {
                    spans[i].appendChild(input);
                }   
            }   
        }

        let dstext = jQuery(".wpcf7-checkbox .wpcf7-list-item-label").text();
        dstext = dstext.replace("Datenschutzerklärung", "<a href='/datenschutz/'>Datenschutzerklärung</a>");
        //console.log(dstext);
        jQuery(".wpcf7-checkbox .wpcf7-list-item-label").html(dstext);
    }




    function initZeynep() {
        var zeynep = jQuery('.zeynep').zeynep({
            load: function (element, options) {
                //console.log('zeynepjs menu is successfully loaded')
              },
            onClosed: function () {
              // enable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "");
        
              //console.log('the <a href="https://www.jqueryscript.net/tags.php?/side menu/">side menu</a> is closed.');
            },
            onOpened: function () {
              // disable main wrapper element clicks on any its children element
              jQuery("body main").attr("style", "pointer-events: none;");
        
              console.log('the side menu is opened.');
            }
            
        });

        jQuery(".mburger").click(function () {
            if (jQuery("html").hasClass("zeynep-opened")) {
              zeynep.close();
            } else {
              zeynep.open();
            }
        });

        jQuery(".zeynep-overlay").click(function () {
            zeynep.close();
        });
        jQuery("#mobileMenuClose-inner").click(function () {
            zeynep.close();
        });
    }


    function initSwiperSliderBlog() {
        var swiper = new Swiper(".swiper-blog", {
            modules: [Pagination, Autoplay, Lazy],
            loop: 'true',
            autoHeight: 'false',
            slidesPerView: 1,
            spaceBetween: 10,
            preloadImages: false,
            lazy: {
                checkInView: false,
                loadPrevNext: true,
            },
            
            pagination: {
                el: ".swiper-pagination-blog",
                clickable: 'true',
            },
            /*
            navigation: {
                nextEl: '.swiper-button-next-blog',
                prevEl: '.swiper-button-prev-blog',
              },
              */
            autoplay: {
                delay: 4000,
            },
            // Responsive breakpoints
            breakpoints: {
                // when window width is >= 320px
                220: {
                slidesPerView: 1,
                spaceBetween: 10
                },
                // when window width is >= 480px
                480: {
                slidesPerView: 2,
                spaceBetween: 20
                },
                // when window width is >= 640px
                880: {
                slidesPerView: 3,
                spaceBetween: 20
                },
                1080: {
                    slidesPerView: 3,
                    spaceBetween: 40
                    }
            }
        });
    }

    
    function initphotoswipe() {
        const lightbox = new PhotoSwipeLightbox({
            gallery: '#swipegallery',
            children: 'a',
            bgClickAction: 'close', 
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();
    }

    function initMasonry() {

        //var $msnry = new Masonry( '#swipegallery', {
        var $msnry = $('#swipegallery').masonry({
            itemSelector: '.grid-item',
            percentPosition: true,
            columnWidth: '.grid-sizer'
          });

          //$msnry.masonry();

         // $msnry.hide();

        //let container = jQuery('#swipegallery');
        
        $msnry.imagesLoaded().progress( function() {
            $msnry.masonry();
          });
        

    };

    function initCTA() {
        $(' #fd-header-main-cta-item-1 a').on( "click", function() {
            $( '#fd-header-main-cta-item-1' ).addClass( "active" );
            $( '#fd-header-main-cta-item-2' ).addClass( "active" );
            $( '#fd-header-main-cta-item-3' ).addClass( "active" );

            $( ' #fd-header-main-cta-item-1 ' ).addClass( "current" );
            $( ' #fd-header-main-cta-item-3 ' ).removeClass( "current" );
        } );

        $(' #fd-header-main-cta-item-3 a').on( "click", function() {
            $( '#fd-header-main-cta-item-1' ).addClass( "active" );
            $( '#fd-header-main-cta-item-2' ).addClass( "active" );
            $( '#fd-header-main-cta-item-3' ).addClass( "active" );

            $( ' #fd-header-main-cta-item-1 ' ).removeClass( "current" );
            $( ' #fd-header-main-cta-item-3 ' ).addClass( "current" );
        } );
        $( ' .cta_close a ' ).on( "click", function() {
            $( ' #fd-header-main-cta-item-1 ' ).removeClass( "current" );
            //$( ' #fd-header-main-cta-item-2 ' ).removeClass( "current" );
            $( ' #fd-header-main-cta-item-3 ' ).removeClass( "current" );
            $( ' #fd-header-main-cta-item-1 ' ).removeClass( "active" );
            $( ' #fd-header-main-cta-item-2 ' ).removeClass( "active" );
            $( ' #fd-header-main-cta-item-3 ' ).removeClass( "active" );
        } );

    }

    function initAccordionOpen() {
        console.log("initAccordionOpen");
        if (jQuery(' .page-id-84 ').length) {
            console.log("initAccordionOpen - page-id-84");
            var hash = window.location.hash;
            console.log("hash: " + hash);
          //  jQuery(' .page-id-84 .accordion .collapse ').addClass( "show" );
          //  jQuery(' .page-id-84 .accordion .btn-link ').attr( "aria-expanded", "true" );
        }

    }


    function initSmoothScroll () {
       // console.log("initSmoothScroll");
        jQuery('.menu-item-5 li a').smoothScroll({offset: -50, speed: 800, easing: 'swing'});
        jQuery('.menu-item-10 li a').smoothScroll({offset: -50, speed: 800, easing: 'swing'});
        jQuery('.menu-item-92 li a').smoothScroll({offset: -50, speed: 800, easing: 'swing'});
        jQuery('.menu-item-37 li a').smoothScroll({offset: -50, speed: 800, easing: 'swing'});
            
        function scrollToAnchor(aid){
            var hashfd = window.location.hash;
            //console.log("hashfd: " + hashfd);
            let aTag = jQuery(hashfd);
            if (aTag.length) {
                jQuery('html, body').animate({
                    scrollTop: aTag.offset().top - 50
                }, 1000); 
            }

        }

        if ( jQuery(' .page-id-2 ').length || jQuery(' .page-id-8 ').length || jQuery(' .page-id-90 ').length || jQuery(' .page-id-35 ').length ) {
            scrollToAnchor();
        }
            
    }


    return {
        init: function ($) {
            initPageload();
           // initSuperfish();
            //initMmenu(); 
            ////initRefreshAfterResize();
            initShrinkedHeader();
            initMatchHeight();
            initSwiperHero();
            initCF7Styling();
            initCookiesreopen();
            initTooltips();
            initZeynep();
            //initAccordionOpen();

            initSmoothScroll();
            initSwiperSliderBlog();
          //  initphotoswipe();
          //  initMasonry();
          //  init_fd_lottie();
           // LottieScrollTrigger(); 
            initCTA();
        }
    }
}(jQuery);


jQuery(document).ready(function () {
    //console.log('ready  ??');
    App.init();


});



